import $ from 'jquery';
import * as IC from '../../in_control/in_control';
import '../../in_control/lodash';
import '../../in_control/bootstrap';
import enableBootstrapTooltips from '../../in_control/bootstrap/tooltips';

// Initialize app JavaScript after Bootstrap
function initialize() {
console.log('[PICTURES/INDEX] INITIALIZE');

  $('.model-form').on('click', '.operations .btn', function(evt) {
    let clicked   = $(this),
        row       = clicked.closest('.picture, .estate-heading'),
        container = row.closest('.container'),
        form      = container.closest('.model-form');

    if(clicked.is('.add-row')) {
      let template = $(form.find('template').html());
      IC.Form.rewriteTemplateIds(template);
      enableBootstrapTooltips(template);
      row.after(template);
    } else if(clicked.is('.delete-row-confirm')) {
      row.addClass('bg-warning bg-opacity-50');
    } else if(clicked.is('.delete-row')) {
      row.find(':input.destroy').val(true);
      row.hide();
    }
  });

  $('.model-form').on('hide.bs.dropdown', function(evt) {
    $('.model-form .picture').removeClass('bg-warning bg-opacity-50');
  });
}

export {
  initialize
};
