import '../stylesheets/application'

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

import '../src/application/main';

Rails.start()
ActiveStorage.start()
