import $ from 'jquery';
import '../../in_control/lodash';
import '../../in_control/bootstrap';
import baguetteBox from 'baguettebox.js';
import ResponsiveAutoHeight from 'responsive-auto-height';
import * as bootstrap from 'bootstrap';

const parser = require('ua-parser-js');
var playersBySlide, parsedAgent;

function initializeGoogleMaps() {
  $('[data-latitude][data-longitude]').each(function(ci, container) {
    container = $(container);

    let pos = new google.maps.LatLng(container.data('latitude'), container.data('longitude'));
    let map = new google.maps.Map(container[0], {
      center:    pos,
      zoom:      container.data('zoom'),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });
    new google.maps.Marker({ map: map, position: pos });
  });
}


function initializeBaguetteBox() {
  baguetteBox.run('.carousel');
}


function revealSlideYouTube(player) {
  if(!player) return;

  let slideProps = playersBySlide[player.myIndex];
  if(!slideProps) return;

  let slide = slideProps.slide;
  if(!slide) return;

  let image = slide.find('.image-container'),
      video = slide.find('.video-container');
  if(!image || !video) return;

  image.hide();
  video.show();
}


function initializeYouTube() {
  function onPlayerReady(event) {
    const player = event.target;
    revealSlideYouTube(player);
  }

  window.onYouTubeIframeAPIReady = function() {
    $('.carousel .carousel-item').each(function(si, slide) {
      slide = $(slide);

      let video = slide.find('.video-placeholder');
      if(!video.length) return;

      let videoId = video.data('video-id');
      let player  = new YT.Player(video.get(0), {
        videoId:    videoId,
        playerVars: {
          'playsinline': 1
        },
        events: {
          'onReady': onPlayerReady
        }
      });
      console.log(`Initialize ${videoId}`);

      player.myIndex   = si;
      player.myVideoId = videoId;

      playersBySlide[si] = {slide: slide, player: player};
    });
  }

  // Handle slide changes AWAY from videos.
 $('.carousel').on('slide.bs.carousel', function(event) {
   let slide = playersBySlide[event.from];
   if(!slide) return;

   let player = slide.player;
   if(!player || !player.pauseVideo) return;

   player.pauseVideo();
 });

  if(window.YT && window.YT.Player)
    window.onYouTubeIframeAPIReady();
}


function initializeUAParser() {
  parsedAgent = parser(navigator.userAgent);
}


function initialize() {
  console.log('[ESTATES/SHOW] INITIALIZE');

  playersBySlide = [];

  initializeUAParser();
  initializeGoogleMaps();
  initializeBaguetteBox();
  initializeYouTube();
}


export {
  initialize
};
