import $ from 'jquery';
import '../../in_control/lodash';
import '../../in_control/bootstrap';

// Initialize app JavaScript after Bootstrap
function initialize() {
  console.log('[ESTATES/INDEX] INITIALIZE');

  $('.stations, .prefecture-cities').on('click', 'a[data-key]', function(evt) {
    let clicked  = $(this);
    let fieldKey = clicked.data('key');
    let fieldVal = clicked.data('id');

    let form    = $('#estate_search_form'),
        newForm = $('<FORM></FORM>');
    newForm.attr('action', form.attr('action'));
    newForm.attr('emthod', form.attr('method'));
    $('BODY').append(newForm);

    let formField = form.find(`#estate_search_${fieldKey}`),
        newField  = formField.clone();
    newField.val(fieldVal);

    newForm.append(newField);
    newForm.submit();
  });

  $('#estate_search_sort').on('change', function(evt) {
    let form = $(this).closest('FORM');
    form.submit();
  });

  $('.contact-batch').on('click', function(evt) {
    let selected = $('.estate-contact input:checked');
    if(!selected.length)
      return;

    let selected_ids = $.map(selected, function(s, si) { return s.value });
    window.location = `https://www.taiho-k.net/blog/contact_new/buy?estate_ids=${selected_ids.join(',')}`;
  });
}

export {
  initialize
};
