import $ from 'jquery';
import * as bootstrap from 'bootstrap';

function icInitializeBootstrap() {
  console.log("[icInitializeBootstrap] START")

  if(typeof(window.beforeBootstrapInitialize) === 'function') {
    console.log("[icInitializeBootstrap] CALLING beforeBootstrapInitialize");
    window.beforeBootstrapInitialize();
  }

  if(typeof(window.icInitializeBootstrapMain) === 'function') {
    console.log("[icInitializeBootstrap] CALLING icInitializeBootstrapMain");
    window.icInitializeBootstrapMain();
  }

  if(typeof(window.afterBootstrapInitialize) === 'function') {
    console.log("[icInitializeBootstrap] CALLING afterBootstrapInitialize");
    window.afterBootstrapInitialize();
  }
}

export {
  icInitializeBootstrap
};
