import $ from 'jquery';
import '../in_control/bootstrap';
import enableBootstrapTooltips from '../in_control/bootstrap/tooltips';
import * as EstatesIndex from './estates/index';
import * as EstatesShow from './estates/show';
import * as EstatesNew from './estates/new';
import * as EstateNeighborMapsIndex from './estate_neighbor_maps/index';
import * as PicturesIndex from './pictures/index';

// Initializer
$(function($) {
  let body = $(document.body);

  if(body.is('.admins-estates-index, .public-estates-index')) {
    EstatesIndex.initialize();
  } else if(body.is('.admins-estates-show, .public-estates-show')) {
    EstatesShow.initialize();
  } else if(body.is('.admins-estates-edit, .admins-estates-update, .admins-estates-new, .admins-estates-create')) {
    EstatesNew.initialize();
  } else if(body.is('.admins-estates-estate_neighbor_maps')) {
    EstateNeighborMapsIndex.initialize();
  } else if(body.is('.admins-estates-pictures')) {
    PicturesIndex.initialize();
  }

  enableBootstrapTooltips();

  $(window).scroll(function () {
    $('.back-to-top')[$(this).scrollTop() > 50 ? 'fadeIn' : 'fadeOut']();
  });
  $('.back-to-top').click(function () {
    $('body, html').animate({
      scrollTop: 0
    }, 400);
    return false;
  });
});
