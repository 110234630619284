import $ from 'jquery';
import * as IC from '../../in_control/in_control';
import '../../in_control/lodash';
import '../../in_control/bootstrap';
import enableBootstrapTooltips from '../../in_control/bootstrap/tooltips';

// Initialize app JavaScript after Bootstrap
function initialize() {
  console.log('[ESTATES/NEW] INITIALIZE');

  $('.route-station-estate-maps').on('click', '.operations .btn', function(evt) {
    let clicked   = $(this),
        row       = clicked.closest('.route-station-estate-map'),
        container = row.closest('.route-station-estate-maps');

    if(clicked.is('.add-row')) {
      let template = $(container.find('template').html());
      IC.Form.rewriteTemplateIds(template);
      enableBootstrapTooltips(template);
      row.after(template);
    } else if(clicked.is('.delete-row-confirm')) {
      row.addClass('bg-warning bg-opacity-50');
    } else if(clicked.is('.delete-row')) {
      row.find(':input.destroy').val(true);
      row.hide();
    }
  });

  $('.route-station-estate-maps').on('hide.bs.dropdown', function(evt) {
    $('.route-station-estate-maps .route-station-estate-map').removeClass('bg-warning bg-opacity-50');
  });
}

export {
  initialize
};
